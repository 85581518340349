.steps-content {
    min-height: 200px;
    margin-top: 16px;
    padding-top: 20px;
    text-align: center;
    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
  }
  .steps-action {
    margin-top: 24px;
  }
  .step-main {
      min-height: 300px;
      text-align: left;
      margin: auto;
  }
  .step-upload {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }
  .step-head {
      max-width: 615px;
      justify-content: flex-start;
      text-align: left;
      margin: auto;
  }
  .text-center { 
      text-align: center;
  }