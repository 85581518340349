#control-hooks {
  display: flex;
}
.dashboard {
  // background: linear-gradient(90deg,#c7c5f4,#776bcc);
  min-height: 100vh;
}
// .d-flex {
//   display: flex;
//   justify-content: center;
// }
#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
  justify-content: space-between;
  display: flex;
  padding: 25px 15px;
}
.site-layout .site-layout-background .ant-tabs-top {
  width: 100%;
}
.mb-20 {
  margin-bottom: 20px;
}
.ml-20 {
  margin-left: 20px;
}