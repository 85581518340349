.card-upload .ant-card-body {
    display: flex;
}
.card-upload .ant-card {
    margin-top: 20px;
}
.list-issue-alert {
    display: flex;
}
.list-issue-alert > .ant-table-wrapper {
    width: 100%;
    margin-right: 10px;
}
.center-box {
    display: flex;
    justify-content: center;
}